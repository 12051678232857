.App {
  margin: auto;
}

.buffer {
  height: 100px;
}

body {
  background-color: #2167BD;
}

#customized-dialog-title {
  text-align: left;
  margin-left: 118px;
  margin-top: 12px;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 14px;
}

.icons {
  margin-left: 5px;
  margin-right: 5px;
}